import { useState } from "react";
import { HashLink, NavHashLink } from "react-router-hash-link";
import { Container } from "./styles";

export function Header() {
  const [expand, setExpand] = useState(false);

  function closeMenu() {
    setExpand(false);
  }

  return (
    <Container className="header-fixed">
      <HashLink smooth to="#home" className="logo">
        <span>DaveApps</span>
      </HashLink>

      <nav className={expand ? "active" : ""}>
        <NavHashLink smooth to="/#home" onClick={closeMenu}>
          Home
        </NavHashLink>
        <NavHashLink smooth to="/#about" onClick={closeMenu}>
          About
        </NavHashLink>
        <NavHashLink
          smooth
          to="/#contact"
          onClick={closeMenu}
          className="button"
        >
          Contact
        </NavHashLink>
      </nav>

      <div
        aria-expanded={expand ? "true" : "false"}
        aria-haspopup="true"
        aria-label={expand ? "Date menu" : "Open menu"}
        className={expand ? "menu active" : "menu"}
        onClick={() => {
          setExpand(!expand);
        }}
      ></div>
    </Container>
  );
}
